import React, { useEffect, useState } from "react";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import Layout from "../../components/Layout";
import HeroSection from "../../components/FilmFestival/HeroSection";
import { isMobile } from "react-device-detect";
import { apiRoutes } from "../../constants/apiRoutes";
import axios from "axios";
import { appendImage, createMarkup } from "../../utils";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";
import routes from "../../components/constants/routes";
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import { ImageConstants } from "../../components/constants/Imageconstants";
import { Cookies } from "react-cookie";
import { navigate } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [registerData, setRegisterData] = useState({});
  const fetchRegister = async () => {
    try {
      const constactres = await axios.get(apiRoutes.register);
      setRegisterData(constactres.data.data.attributes);
    } catch (error) {}
  };
  useEffect(() => {
    fetchRegister();
  }, []);

  const formHeightWeb = registerData?.filmFestival_formHeightWeb ?? "250";
  const formHeightMob = registerData?.filmFestival_formHeightMob ?? "270";
  const cookies = new Cookies();

  //Sign up
  const [key, setKey] = useState("Sign-In");
  const [signupdata, setSignupdata] = useState("");
  const [showforgotmodal, setShowforgotmodal] = useState(false);
  const [signupdataerror, setSignupdataerror] = useState(false);
  const [singupfname, setSingupfname] = useState("");
  const [singuplname, setSinguplname] = useState("");
  const [singupemail, setSingupemail] = useState("");
  const [singuppass, setSinguppass] = useState("");
  const [singupconfimpass, setSingupconfimpass] = useState("");
  const [singupnewsletter, setSingupnewsletter] = useState(false);
  const [showaddress, setShowaddress] = useState(false);
  const [singupbrochers, setSingupbrochers] = useState(false);
  const [singupaddone, setSingupaddone] = useState("");
  const [singupaddtwo, setSingupaddtwo] = useState("");
  const [singupcity, setSingupcity] = useState("");
  const [singuppostal, setSinguppostal] = useState("");
  const [singupcontry, setSingupcontry] = useState("");
  const [signuppasserror, setSignuppasserror] = useState(false);
  const [passtype, setPasstype] = useState("password");
  const [passconfirmtype, setPassconfirmtype] = useState("password");
  const [eyeicon, setEyeicon] = useState(ImageConstants.eyeIconcross);
  const [eyeiconconfim, setEyeiconconfim] = useState(
    ImageConstants.eyeIconcross
  );
  const [validEmail, setValidEmail] = useState(true);
  const [validUserName, setValidUserName] = useState(true);
  const [validlastName, setValidLastName] = useState(true);
  const [singupcapcha, setSingupcapcha] = useState(false);
  const [singupfinishmodal, setsingupfinishmodal] = useState(false);
  const [singupother, setSingupother] = useState("");
  const [singupshowother, setSingupshowother] = useState(false);

  //Login
  const [loginemail, setLoginemail] = useState("");
  const [loginpassword, setLoginPassword] = useState("");
  const [loginerrors, setLoginerros] = useState(false);
  const [loginpasstype, setLoginpasstype] = useState("password");
  const [loginpassicon, setLoginpassicon] = useState(
    ImageConstants.eyeIconcross
  );
  const [logincapcha, setLogincapcha] = useState(false);
  const [showloginconfimmodal, setShowloginconfimmodal] = useState(false);
  const [linkexpiremodal, setLinkexpiremodal] = useState(false);

  //Forgot
  const [forgotpass, setForgotpassword] = useState("");
  const [forgoterror, setForgoterror] = useState("");
  const [forgotsucess, setForgotsucess] = useState(false);

  //reset
  const [dataerror, setDataerror] = useState("");
  const [showresetmodal, setShowresetmodal] = useState(false);
  const [resetpass, setResetpass] = useState("");
  const [resetpassconfim, setResetpassconfim] = useState("");
  const [passmacherror, setPassmacherror] = useState(false);
  const [resettoken, setResettoken] = useState("");
  const [resetemail, setResetemail] = useState("");
  const [resetsucess, setResetsucess] = useState(false);
  const [resetpasstype, setResetpasstype] = useState("password");
  const [resetpasstypeconfim, setResetpasstypeconfim] = useState("password");
  const [resetpasseye, setResetpasseye] = useState(ImageConstants.eyeIconcross);
  const [resetpasseyeconfim, setResetpasseyeconfirm] = useState(
    ImageConstants.eyeIconcross
  );
  const [adderrorr, setAdderrorr] = useState(false);
  function validateEmail(email) {
    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var isValid = pattern.test(email);
    if (isValid) {
      setValidEmail(true);
      return true;
    } else {
      setValidEmail(false);
      return false;
    }
  }
  const handleresetClose = () => {
    setShowresetmodal(false);
  };
  const handleresetShow = () => {
    setShowresetmodal(true);
  };

  useEffect(() => {
    appendImage("rich-editor");
  }, [registerData]);

  const seoData = {
    title: "Register for Film Festivals Luxembourg | Book Your Tickets",
    description:
      "Register for Film Festivals Luxembourg to secure your tickets. Join us for exclusive screenings, discussions, and cinematic experiences. Reserve your seat now.",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFRegister}/`,
  };

  const setTabStatus = (route) => {
    navigate(route);
  };
  const handleforgotclose = (e) => {
    setShowforgotmodal(false);
    e.preventDefault();
  };
  const handlesignupfinish = (e) => {
    setsingupfinishmodal(false);
    e.preventDefault();
  };

  const handleforgotshow = (e) => {
    setShowforgotmodal(true);
    e.preventDefault();
  };
  const singupuser = (e) => {
    e.preventDefault();
    const isValidated = validateEmail(singupemail);
    if (
      isValidated &&
      singupfname.trim().length &&
      singuplname.trim().length &&
      singupbrochers === true
    ) {
      if (
        singupaddone.length < 1 ||
        singupcity.length < 1 ||
        singuppostal.length < 1 ||
        singupcontry.length < 1 ||
        singupcontry === "Other"
      ) {
        setAdderrorr(true);
      } else {
        axios
          .post(process.env.GATSBY_API_LOGIN_URL + "/api/register", {
            first_name: singupfname,
            last_name: singuplname,
            email: singupemail,
            password: singuppass,
            password_confirmation: singupconfimpass,
            address1: singupaddone,
            address2: singupaddtwo,
            city: singupcity,
            postal_code: singuppostal,
            country: singupcontry,
            newsletter: singupnewsletter,
            brochure: singupbrochers,
          })
          .then((resopnse) => {
            console.log(resopnse.data.data.user_info, "info");
            setSignupdata(resopnse.data.data.user_info);
            cookies.set("token", resopnse.data.data.token);
            setsingupfinishmodal(true);
            setSignupdataerror(false);
          })
          .catch((error) => {
            console.log(error);
            setSignupdataerror(false);
            setSignupdataerror(error.response.data.data);
          });
      }
    } else {
      console.log("else");
      axios
        .post(process.env.GATSBY_API_LOGIN_URL + "/api/register", {
          first_name: singupfname,
          last_name: singuplname,
          email: singupemail,
          password: singuppass,
          password_confirmation: singupconfimpass,
          address1: singupaddone,
          address2: singupaddtwo,
          city: singupcity,
          postal_code: singuppostal,
          country: singupcontry,
          newsletter: singupnewsletter,
          brochure: singupbrochers,
        })
        .then((resopnse) => {
          console.log(resopnse.data.data.user_info, "info");
          setSignupdata(resopnse.data.data.user_info);
          cookies.set("token", resopnse.data.data.token);
          setsingupfinishmodal(true);
          setSignupdataerror(false);
        })
        .catch((error) => {
          console.log(error);
          setSignupdataerror(false);
          setSignupdataerror(error.response.data.data);
        });
    }
  };

  //Sing Up funtion
  const handleSignupfistname = (e) => {
    setValidUserName(false);
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    setSingupfname(sanitizedValue);
  };

  const handleSignuplastname = (e) => {
    setValidLastName(false);
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    setSinguplname(sanitizedValue);
  };

  const handleSignupemail = (e) => {
    const isValid = validateEmail(e.target.value);
    if (isValid) setValidEmail(true);
    setSingupemail(e.target.value);
  };
  const handleSignuppass = (e) => {
    setSinguppass(e.target.value);
  };
  const handleSignupconfirmpass = (e) => {
    setSingupconfimpass(e.target.value);
  };

  const handleSignupnewsletter = (e) => {
    setSingupnewsletter(!singupnewsletter);
  };
  const handlesingupbrochers = (e) => {
    setSingupbrochers(!singupbrochers);

    setShowaddress(!showaddress);
  };

  const handlesingupaddressone = (e) => {
    setSingupaddone(e.target.value);
  };
  const handlesingupaddresstwo = (e) => {
    setSingupaddtwo(e.target.value);
  };
  const handlesingupcity = (e) => {
    setSingupcity(e.target.value);
  };
  const handlesinguppostal = (e) => {
    setSinguppostal(e.target.value);
  };

  const handlesingupcountry = (e) => {
    if (e.target.value !== "Other") {
      setSingupshowother(false);
      setSingupcontry(e.target.value);
    } else {
      setSingupshowother(true);
      setSingupcontry(e.target.value);
    }
  };

  const handlerother = (e) => {
    setSingupother(e.target.value);
    setSingupcontry(e.target.value);
  };

  const handlepassword = (e) => {
    e.preventDefault();
    if (passtype === "password") {
      setPasstype("text");
      setEyeicon(ImageConstants.eyeIcon);
    } else {
      setPasstype("password");
      setEyeicon(ImageConstants.eyeIconcross);
    }
  };

  const handleconfirmpassword = (e) => {
    e.preventDefault();
    if (passconfirmtype === "password") {
      setPassconfirmtype("text");
      setEyeiconconfim(ImageConstants.eyeIcon);
    } else {
      setPassconfirmtype("password");
      setEyeiconconfim(ImageConstants.eyeIconcross);
    }
  };

  const passvefiry = () => {
    if (singupconfimpass !== singuppass) {
      setSignuppasserror(true);
    } else {
      setSignuppasserror(false);
    }
  };
  useEffect(() => {
    passvefiry();
  }, [singupconfimpass]);

  //Login
  const handlelogin = (e) => {
    e.preventDefault();

    axios
      .post(process.env.GATSBY_API_LOGIN_URL + "/api/login", {
        email: loginemail,
        password: loginpassword,
      })
      .then((resopnse) => {
        console.log(resopnse);
        cookies.set("token", resopnse.data.data.token);
        if (resopnse.data.data.email_verified === true) {
          setTabStatus(routes.FFMyprofile);
          localStorage.setItem("token", resopnse.data.data.token);
        } else {
          setShowloginconfimmodal(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoginerros(error.response.data.message);
      });
  };
  const handleloginemail = (e) => {
    setLoginemail(e.target.value);
  };
  const handleloginpassword = (e) => {
    setLoginPassword(e.target.value);
  };
  const handlepasslogin = (e) => {
    e.preventDefault();
    if (loginpasstype === "password") {
      setLoginpasstype("text");
      setLoginpassicon(ImageConstants.eyeIcon);
    } else {
      setLoginpasstype("password");
      setLoginpassicon(ImageConstants.eyeIconcross);
    }
  };
  const handleclosesignconfimmodal = (e) => {
    setShowloginconfimmodal(false);
    e.preventDefault();
  };
  const handlecloselinkmodal = (e) => {
    setLinkexpiremodal(false);
    e.preventDefault();
  };
  //forgot pass
  const handleforgotpassword = (e) => {
    e.preventDefault();
    axios
      .post(process.env.GATSBY_API_LOGIN_URL + "/api/forgot_password", {
        email: forgotpass,
      })
      .then((resopnse) => {
        console.log(resopnse);
        setForgotsucess(true);
      })
      .catch((error) => {
        console.log(error);
        setForgoterror(error?.response?.data);
      });
  };
  const handlerforgotpass = (e) => {
    setForgotpassword(e.target.value);
  };

  //Reset
  const handleresetpassword = (e) => {
    e.preventDefault();
    axios
      .post(process.env.GATSBY_API_LOGIN_URL + "/api/reset_password", {
        password: resetpass,
        password_confirmation: resetpassconfim,
        token: resettoken,
        email: resetemail,
      })
      .then((resopnse) => {
        console.log(resopnse);
        setResetsucess(true);
        setTimeout(() => {
          handleresetClose(false);
          setTabStatus(routes.FFRegister);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setDataerror(error?.response?.data?.data);
      });
  };
  const handleresetpassEye = (e) => {
    e.preventDefault();
    if (resetpasstype === "password") {
      setResetpasstype("text");
      setResetpasseye(ImageConstants.eyeIcon);
    } else {
      setResetpasstype("password");
      setResetpasseye(ImageConstants.eyeIconcross);
    }
  };
  const handleresetpassEyeconfirm = (e) => {
    e.preventDefault();
    if (resetpasstypeconfim === "password") {
      setResetpasstypeconfim("text");
      setResetpasseyeconfirm(ImageConstants.eyeIcon);
    } else {
      setResetpasstypeconfim("password");
      setResetpasseyeconfirm(ImageConstants.eyeIconcross);
    }
  };
  const handlerresetpass = (e) => {
    setResetpass(e.target.value);
  };
  const handlerresetpassconfirm = (e) => {
    setResetpassconfim(e.target.value);
  };

  const resertpassvalid = () => {
    if (resetpassconfim !== resetpass) {
      setPassmacherror(true);
    } else {
      setPassmacherror(false);
    }
  };
  useEffect(() => {
    resertpassvalid();
  }, [resetpassconfim]);

  useEffect(() => {
    const url = window.location.href.split("?");
    if (url[url.length - 1] === "isOpen=true") {
      setShowresetmodal(true);
    }
  }, []);

  useEffect(() => {
    const url = window.location.href.split("=");
    const tokenstr = url[1]?.split("&")[0];
    setResettoken(tokenstr);
  }, []);

  useEffect(() => {
    const url = window.location.href.split("=");
    const emailstr = url[2]?.split("?")[0];
    setResetemail(emailstr);
  }, []);

  const handlersingupcapcha = (value) => {
    console.log("Captcha value:", value);
    setSingupcapcha(true);
  };

  const handlersingincapcha = (value) => {
    console.log("Captcha value:", value);
    setLogincapcha(true);
  };
  useEffect(() => {
    const url = window.location.href.split("=");
    if (url[url.length - 1] === "failed") {
      setLinkexpiremodal(true);
    }
  }, []);
  //resend email
  const fechresendlink = () => {
    const token = cookies.get("token");
    axios
      .get(process.env.GATSBY_API_LOGIN_URL + "/api/email/resend", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((resopnse) => {
        console.log(resopnse, "resend link");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />

      <div className="film-festival">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pr-mob-0">
              <HeroSection />
            </div>

            <div className="col-12 head-tab mt-40">
              <FilmFestivalHeader />

              {Object.values(registerData).length ? (
                <>
                  {" "}
                  <div className="container">
                    <div
                      className="my-4 rich-editor"
                      dangerouslySetInnerHTML={createMarkup(
                        registerData?.flim_festival_description
                      )}
                    ></div>
                  </div>
                  <div
                    className="tab-content loginTabcontent"
                    id="nav-tabContent"
                  >
                    <div className="film-register mt-40 LoginTabs">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="Sign-Up" title="Sign Up">
                          <div className="signup-box">
                            <form onSubmit={singupuser}>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.usericon}
                                    alt="user icon"
                                  />
                                  First Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name=" First Name"
                                  placeholder="Type your first name"
                                  value={singupfname}
                                  invalid={
                                    !singupfname.trim().length && !validUserName
                                  }
                                  onChange={handleSignupfistname}
                                />
                                {signupdataerror ? (
                                  <p className="errors">
                                    {signupdataerror?.first_name}
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.usericon}
                                    alt="user icon"
                                  />
                                  Last Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="Last Name"
                                  placeholder="Type your last name"
                                  value={singuplname}
                                  invalid={
                                    !singuplname.trim().length && !validlastName
                                  }
                                  onChange={handleSignuplastname}
                                />
                                {signupdataerror ? (
                                  <p className="errors">
                                    {signupdataerror?.last_name}
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.emailicon}
                                    alt="user icon"
                                  />
                                  Email <span>*</span>
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Type your email address"
                                  value={singupemail}
                                  onChange={handleSignupemail}
                                  className={`form-control ${
                                    !validEmail ? "invalid-el" : ""
                                  }`}
                                  invalid={!validEmail}
                                />
                                {!validEmail ? (
                                  <p className="errors">Enter valid email</p>
                                ) : null}
                                {signupdataerror ? (
                                  <p className="errors mb-3">
                                    {signupdataerror?.email}
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.passwordicon}
                                    alt="user icon"
                                  />
                                  Password <span>*</span>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type={passtype}
                                    className="form-control"
                                    name="Password"
                                    placeholder="Type 6+ character password"
                                    value={singuppass}
                                    onChange={handleSignuppass}
                                  />
                                  <button
                                    className="eye-icon"
                                    onClick={handlepassword}
                                  >
                                    <img src={eyeicon} alt="icon" />
                                  </button>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.passwordicon}
                                    alt="user icon"
                                  />
                                  Confirm Password <span>*</span>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type={passconfirmtype}
                                    className="form-control"
                                    name="confirmPassword"
                                    placeholder="Repeat your password to confirm"
                                    value={singupconfimpass}
                                    onChange={handleSignupconfirmpass}
                                  />
                                  <button
                                    className="eye-icon"
                                    onClick={handleconfirmpassword}
                                  >
                                    <img src={eyeiconconfim} alt="icon" />
                                  </button>
                                </div>
                                {signuppasserror ? (
                                  <p className="errors">
                                    Please make sure your passwords match
                                  </p>
                                ) : null}
                                {signupdataerror ? (
                                  <p className="errors">
                                    {signupdataerror?.password}
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-group">
                                <p className="subTitle">
                                  I would like to receive :
                                </p>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="eNewsletters"
                                    onChange={handleSignupnewsletter}
                                    checked={singupnewsletter}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="eNewsletters"
                                  >
                                    eNewsletters
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="Printed"
                                    checked={singupbrochers}
                                    onChange={handlesingupbrochers}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Printed"
                                  >
                                    Printed brochures by post
                                  </label>
                                </div>
                              </div>
                              {showaddress ? (
                                <div className="form-group">
                                  <label htmlFor="name">
                                    <img
                                      src={ImageConstants.addressIcon}
                                      alt="user icon"
                                    />
                                    Address <span>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Address 1"
                                    value={singupaddone}
                                    onChange={handlesingupaddressone}
                                  />
                                  {signupdataerror ? (
                                    <p className="errors mb-2">
                                      {signupdataerror?.address1}
                                    </p>
                                  ) : null}
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Address 2"
                                    value={singupaddtwo}
                                    onChange={handlesingupaddresstwo}
                                  />

                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Town/City"
                                    value={singupcity}
                                    onChange={handlesingupcity}
                                  />
                                  {signupdataerror ? (
                                    <p className="errors mb-2">
                                      {signupdataerror?.city}
                                    </p>
                                  ) : null}
                                  <input
                                    type="text"
                                    className="form-control mb-2"
                                    name="confirmPassword"
                                    placeholder="Postal Code"
                                    value={singuppostal}
                                    onChange={handlesinguppostal}
                                  />
                                  {signupdataerror ? (
                                    <p className="errors mb-2">
                                      {signupdataerror?.postal}
                                    </p>
                                  ) : null}
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={handlesingupcountry}
                                    Value={singupcontry}
                                  >
                                    <option>Country</option>
                                    <option value="Luxembourg">
                                      Luxembourg
                                    </option>
                                    <option value="Belgium">Belgium</option>
                                    <option value="France">France</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Other">
                                      Other (Please specify)
                                    </option>
                                  </select>
                                  {singupshowother ? (
                                    <input
                                      type="text"
                                      className="form-control mb-2 mt-2"
                                      name="other"
                                      placeholder="Please specify"
                                      value={singupother}
                                      onChange={handlerother}
                                    />
                                  ) : null}
                                  {signupdataerror ? (
                                    <p className="errors mb-2">
                                      {signupdataerror?.country}
                                    </p>
                                  ) : null}
                                  {adderrorr ? (
                                    <p className="errors">
                                      Please fill Address details
                                    </p>
                                  ) : null}
                                </div>
                              ) : null}
                              <div className="captcha text-center mb-3">
                                <ReCAPTCHA
                                  sitekey="6Lcni2QpAAAAAM9cjwOFTgl1HN65T1WevclOfjZv"
                                  onChange={handlersingupcapcha}
                                />
                              </div>
                              <div className="form-group text-center">
                                <button
                                  className="btn submit-btn"
                                  disabled={
                                    singupfname.length < 1 ||
                                    singuplname.length < 1 ||
                                    singupemail.length < 1 ||
                                    singuppass.length < 6 ||
                                    singupconfimpass.length < 6 ||
                                    singuppass !== singupconfimpass ||
                                    !singupcapcha
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </Tab>
                        <Tab eventKey="Sign-In" title="Sign In">
                          <div className="signup-box">
                            <form>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.emailicon}
                                    alt="user icon"
                                  />
                                  Email <span>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder="Type your email address"
                                  value={loginemail}
                                  onChange={handleloginemail}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="name">
                                  <img
                                    src={ImageConstants.passwordicon}
                                    alt="user icon"
                                  />
                                  Password <span>*</span>
                                </label>
                                <div className="position-relative">
                                  <input
                                    type={loginpasstype}
                                    className="form-control"
                                    name="Password"
                                    placeholder="Type 6+ character password"
                                    value={loginpassword}
                                    onChange={handleloginpassword}
                                  />
                                  <button
                                    className="eye-icon"
                                    onClick={handlepasslogin}
                                  >
                                    <img src={loginpassicon} alt="icon" />
                                  </button>
                                </div>
                                {loginerrors ? (
                                  <p className="errors">
                                    <img
                                      src={ImageConstants.errorIcon}
                                      alt="error"
                                    />{" "}
                                    {loginerrors}
                                  </p>
                                ) : null}
                              </div>
                              <div className="form-group forgotPassmain">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="Rememberpassword"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="Rememberpassword"
                                  >
                                    Remember password
                                  </label>
                                </div>
                                <div className="forgotpass">
                                  <button
                                    className="btn"
                                    variant="primary"
                                    onClick={handleforgotshow}
                                  >
                                    Forgot password
                                  </button>
                                </div>
                              </div>

                              <div className="captcha text-center mb-3">
                                <div className="captcha text-center mb-3">
                                  <ReCAPTCHA
                                    sitekey="6Lcni2QpAAAAAM9cjwOFTgl1HN65T1WevclOfjZv"
                                    onChange={handlersingincapcha}
                                  />
                                </div>
                              </div>
                              <div className="form-group text-center">
                                <button
                                  className="btn submit-btn"
                                  onClick={handlelogin}
                                  disabled={
                                    loginemail.length < 1 ||
                                    loginpassword.length < 6 ||
                                    !logincapcha
                                  }
                                >
                                  Log In
                                </button>
                              </div>
                            </form>
                          </div>
                        </Tab>
                      </Tabs>
                      <div className="login-bottom">
                        <img src={ImageConstants.loginbg} alt="login" />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p className="text-center" style={{ margin: "15em 0" }}></p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Forgot password modal */}
      <Modal
        show={showforgotmodal}
        onHide={handleforgotclose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="forgotModal"
      >
        <Modal.Body>
          <div className="reset-mail-box">
            {forgotsucess ? (
              <div className="confirm-mail">
                <h4>Check Your Email</h4>
                <p>
                  We have sent a reset password link to{" "}
                  <strong>{forgotpass}</strong>{" "}
                </p>
                <p className="resend">
                  Don't receive the email?
                  <button onClick={handleforgotpassword} className="btn">
                    Resend
                  </button>
                </p>
              </div>
            ) : (
              <form>
                <h4>Forgot Password?</h4>
                <p>No worries. we'll send you reset instructions.</p>
                <div className="form-group">
                  <label htmlFor="name">
                    <img src={ImageConstants.emailicon} alt="user icon" />
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your email address"
                    value={forgotpass}
                    onChange={handlerforgotpass}
                  />
                  {forgoterror ? (
                    <p className="errors">
                      <img src={ImageConstants.errorIcon} alt="error" />{" "}
                      {forgoterror?.message}
                    </p>
                  ) : null}
                </div>
                <div className="form-group text-center">
                  <button
                    className="btn btn-submit"
                    onClick={handleforgotpassword}
                    disabled={forgotpass.length < 1}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}

            <div className="back-btn">
              <button className="btn" onClick={handleforgotclose}>
                <img src={ImageConstants.backIcon} alt="back" />
                Back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Reset password modal */}
      <Modal
        show={showresetmodal}
        onHide={handleresetClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="resetModal">
            {resetsucess ? (
              <div className="ressertsucess">
                <img src={ImageConstants.sucessIcon} alt="icon" />
                <h4>Password Changed</h4>
                <p>
                  Your password has been changed successfully. <br></br> Use
                  your new password to login
                </p>
              </div>
            ) : (
              <form>
                <h4>Change Password</h4>

                <div className="form-group">
                  <label htmlFor="name">
                    <img src={ImageConstants.passwordicon} alt="user icon" />
                    Password <span>*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={resetpasstype}
                      className="form-control"
                      name="Password"
                      placeholder="Type 6+ character password"
                      value={resetpass}
                      onChange={handlerresetpass}
                    />
                    <button className="eye-icon" onClick={handleresetpassEye}>
                      <img src={resetpasseye} alt="icon" />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">
                    <img src={ImageConstants.passwordicon} alt="user icon" />
                    Confirm Password <span>*</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={resetpasstypeconfim}
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Repeat your password to confirm"
                      value={resetpassconfim}
                      onChange={handlerresetpassconfirm}
                    />
                    <button
                      className="eye-icon"
                      onClick={handleresetpassEyeconfirm}
                    >
                      <img src={resetpasseyeconfim} alt="icon" />
                    </button>
                  </div>
                  {passmacherror ? (
                    <p className="errors mb-3">
                      Please make sure your passwords match
                    </p>
                  ) : null}
                  {dataerror ? (
                    <p className="errors mb-3">{dataerror.password}</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <button
                    className="btn"
                    onClick={handleresetpassword}
                    disabled={
                      resetpass.length < 6 ||
                      resetpassconfim.length < 6 ||
                      resetpass !== resetpassconfim
                    }
                  >
                    Change Password
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* //singup success done */}
      <Modal
        show={singupfinishmodal}
        onHide={handlesignupfinish}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="signupFinishModal"
      >
        <Modal.Body>
          <div className="resetModal">
            <div className="ressertsucess">
              <img src={ImageConstants.sucessIcon} alt="icon" />
              <h4>Verification Email Sent</h4>
              <p>
                We have sent a confirmation email to{" "}
                <strong> {signupdata.email}</strong> <br></br> Please click on
                the link to complete your registration
              </p>
              <p className="dont-forgot mb-0">
                Don't forget to check your spam folder.
              </p>
              <p className="dont-forgot">The link is valid for 60 minutes.</p>
              <p className="didntreceivemail">Didn't Receive the Email?</p>
              <button className="btn" onClick={fechresendlink}>
                Resend Verification Link
              </button>
            </div>

            <div className="back-btn">
              <button className="btn" onClick={handlesignupfinish}>
                <img src={ImageConstants.backIcon} alt="back" />
                Back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Email not verify modal  */}
      <Modal
        show={showloginconfimmodal}
        onHide={handleclosesignconfimmodal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="forgotModal"
      >
        <Modal.Body>
          <div className="reset-mail-box">
            <div className="confirm-mail">
              <img src={ImageConstants.errorIcon} alt="icon" />
              <h4 className="mt-2">Email not yet verified</h4>
              <p>
                Please verify your E-mail ID by clicking on the link in the
                verification email sent to your inbox.
              </p>
              <p className="dont-forgot">The link is valid for 60 minutes.</p>
              <p className="resend">
                Don't receive the email?
                <button className="btn" onClick={fechresendlink}>
                  Resend
                </button>
              </p>
            </div>

            <div className="back-btn">
              <button className="btn" onClick={handleclosesignconfimmodal}>
                <img src={ImageConstants.backIcon} alt="back" />
                Back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={linkexpiremodal}
        onHide={handlecloselinkmodal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="forgotModal"
      >
        <Modal.Body>
          <div className="reset-mail-box">
            <div className="confirm-mail">
              <img src={ImageConstants.errorIcon} alt="icon" />
              <h4 className="mt-2">Verification link expired</h4>
              <p>
                We have resent a verification mail to your inbox. Please verify
                your E-mail ID by clicking on the link in the email sent to your
                inbox.
              </p>
              <p className="resend">
                Don't receive the email?
                <button className="btn" onClick={fechresendlink}>
                  Resend
                </button>
              </p>
            </div>

            <div className="back-btn">
              <button className="btn" onClick={handlecloselinkmodal}>
                <img src={ImageConstants.backIcon} alt="back" />
                Back
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Register;
